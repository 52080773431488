//
// Aside
//

// General mode(all devices)
.aside {
  flex-shrink: 0;

  .menu {
    .menu-item {
      > .menu-content {
        margin-left: 3px;
      }

      > .menu-link {
        @include border-radius(4px);
        border-left: 3px solid transparent;

        > .menu-title {
          color: var(--#{$prefix}gray-700);
          font-weight: $font-weight-semibold;
        }

        > .menu-badge {
          color: $text-muted;
          font-weight: $font-weight-semibold;
          font-size: 0.9rem;
        }

        &.active {
          > .menu-title {
            font-weight: $font-weight-bold;
          }

          > .menu-badge {
            font-weight: $font-weight-bold;
          }

          border-left: 3px solid var(--#{$prefix}primary);
        }
      }
    }
  }
}

// Desktop mode
@include media-breakpoint-up(lg) {
  .aside {
    display: flex;
    width: get($aside-config, width);
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .aside {
    display: none;
  }
}
