.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%; 
    background-color:#0076FF;
    color: white;
    text-align: center;
    line-height: 50px; /* Centrar el texto verticalmente */
    font-size: 24px;
    font-weight: bold;
  }
  
  .avatar_no_circular {
    width: 50px;
    height: 50px;
    border-radius: 5%; 
    margin-right:5px;
    background-color:#0076FF;
    color: white;
    text-align: center;
    line-height: 50px; 
    font-size: 24px;
    font-weight: bold;
  }
  